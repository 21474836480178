import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import chospital from "../../assets/Projects/c-hospital.png";
import hangman from "../../assets/Projects/js-hangman.png";
import cpersonnel from "../../assets/Projects/c-personnel.png";
import cschoolnote from "../../assets/Projects/c-schoolnote.png";
import product from "../../assets/Projects/Entityproduct.png";
import mertnstock from "../../assets/Projects/mernstock.png";
import showroom from "../../assets/Projects/showroom.jpg";
import buildog from "../../assets/Projects/buildog.png";
const Projects = () => {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="blue">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col
            lg={4}
            md={6}
            className="project-card"
            style={{ height: "400px" }}
          >
            <ProjectCard
              imgPath={buildog}
              title="Buildog"
              description="Buildog is a web app that helps bloggers write, schedule, and publish content. It offers a rich text editor, scheduling, one-click publishing, analytics, and customizable themes, streamlining the blogging workflow. Contributed to the web section of the project."
              ghLink="https://github.com/aliwert/buildog"
              demoLink={"https://docs.buildog.dev/"}
            />
          </Col>
          <Col
            lg={4}
            md={6}
            className="project-card"
            style={{ height: "400px" }}
          >
            <ProjectCard
              imgPath={showroom}
              title="Spring Boot Auto Showroom"
              description="Designed a backend service for a gallery management system using Java Spring Boot. Developed secure, role-based access and token refresh capabilities, providing a streamlined platform for managing customer and vehicle data. Enabled businesses to track inventory and manage sales efficiently."
              ghLink="https://github.com/aliwert/auto-showroom-service"
            />
          </Col>
          <Col
            lg={4}
            md={6}
            className="project-card"
            style={{ height: "400px" }}
          >
            <ProjectCard
              imgPath={mertnstock}
              title="MERN Stock App"
              description="This stock app, developed using the MERN stack, allows users to track stock market trends and build investment strategies. I developed both the backend and frontend, ensuring seamless data flow and an intuitive user interface. Key features include real-time data analysis, portfolio management, and customizable alerts to enhance the investment experience."
              ghLink="https://github.com/aliwert/mern-stock-app"
            />
          </Col>
          <Col lg={4} md={6} className="project-card">
            <ProjectCard
              imgPath={hangman}
              title="Hangman Game"
              description="The Hangman game consists of 3 different categories, offering a variety of words to guess from. You have 7 attempts to guess the correct word. If you fail to guess the word within these 7 attempts, the game ends and restarts, allowing you to choose a category and start a new round."
              ghLink="https://github.com/aliwert/hangman-game"
              demoLink="https://aliwert-hangmangame.netlify.app/"
            />
          </Col>
          <Col
            lg={4}
            md={6}
            className="project-card"
            style={{ height: "400px" }}
          >
            <ProjectCard
              imgPath={chospital}
              title="C# Hospital"
              description="The Hospital App consists of 3 different login types: patient, doctor, and secretary. In the patient section, you can create new appointments. In the doctor section, doctors can view patient complaints and hospital announcements. In the secretary section, secretaries can create new appointments, add announcements, and perform a variety of administrative tasks. The data is stored in a Microsoft SQL database."
              ghLink="https://github.com/aliwert/Hospital_Project"
            />
          </Col>
          <Col lg={4} md={6} className="project-card">
            <ProjectCard
              imgPath={cpersonnel}
              title="C# Personnel Registration"
              description="The personnel registration project is designed primarily for institutions like factories. You can manage information related to personnel through this application. When you hire new personnel, you can enter their data into the system. The data is stored in a Microsoft SQL database."
              ghLink="https://github.com/aliwert/Personnel_Registration_App"
            />
          </Col>
          <Col lg={4} md={6} className="project-card">
            <ProjectCard
              imgPath={cschoolnote}
              title="C# School Note"
              description="The School Note project is an application that shows a student's grades based on their entered student number. For teachers, it provides a platform to organize student clubs, add and manage students, input exam scores, and calculate grades. The app also indicates whether a student has successfully completed a class based on their average grades."
              ghLink="https://github.com/aliwert/School_Note_Project"
            />
          </Col>
          <Col lg={4} md={6} className="project-card">
            <ProjectCard
              imgPath={product}
              title="C# Product Management"
              description="The Product Management application is a project designed for stores. This app allows you to register any product into the system, specifying its type, price, and brand. With this application, you can also manage and conduct inventory counts as needed, making it an essential tool for efficient store operations."
              ghLink="https://github.com/aliwert/Entity_Product_Project"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Projects;
